$color_blue: #3f51b5;
$main_color: #43a047;
$main_color_dark: #1b5e20;
$main_color_light: #a5d6a7;
$light_green: #c8e6c9;
$light_gray: #f2f2f2;

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main__logo {
  width: 30px;
  margin-left: 0.4em;
  /*
  position: absolute;
  left: 65px;
  top: 17px;
*/
}
.main__title {
  /*
  position: absolute;
  display: inline;
  top: 15px;
*/
  margin-left: 0.6em;
  font-size: 1.4em;
  font-weight: 400;
}
.main__menu-button {
  margin-left: -15px;
}

@media screen and (max-width: 767px) {
  .main__title,
  .main__logo {
    display: none;
  }
  /*
  .nav__lang {
    top: 12px;
  }
  */
}

@media screen and (min-width: 767px) {
  /*  .nav__lang {
    top: 21px;
  }
*/
}

.nav__lang {
  margin: 0 0 0 20px;
  position: absolute;
  right: 5px;
  height: 100%;
  overflow: hidden;
}

.nav__lang li {
  float: left;
  margin: 0px 1px 0 0;
  list-style: none;
}

.nav__lang a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 21px 14px 22px 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 0px;
}

.nav__lang a:hover,
.nav__lang a.current {
  background-color: #fff;
  color: #2e7d32;
}

#main__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.page {
  h3 {
    color: $main_color_dark;
    font-weight: 400;
  }
  .content {
    padding: 10px;
    a {
      color: $main_color_dark;
    }
    a:hover {
      color: $main_color_light;
    }
    p {
      line-height: 1.8em;
    }
    ul {
      li {
        line-height: 1.8em;
      }
    }
  }
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.birds-list {
  margin: 0 auto 6em auto;
  max-width: 800px;
  padding: 0;
  th {
    padding: 14px;
    background-color: $light_green;
  }
  th.name {
    text-align: left;
  }
  td {
    &.period {
      width: 100px;
      text-align: center;
    }
    padding: 2px 20px;
  }
  tr:nth-child(odd) {
    background-color: $light_gray;
  }
  tr:nth-child(odd) td {
    background-color: $light_gray;
  }
}

@media only screen and (min-width: 1680px) {
  td.period .icon {
    display: none;
  }
  td.latin,
  td.name .wrapper,
  td.name h4 {
    display: none !important;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 420px) {
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  td.latin,
  td.name .wrapper {
    display: none !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  /*thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
*/
  tr {
    display: grid;
    grid-template-columns: 80px calc(70% - 80px) 30%;
    grid-template-rows: 100%;
    border: none;
  }
  .birds-list td {
    padding: 4px;
    border: none;
  }
  .birds-list td.period {
    grid-column-start: 3;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    overflow: hidden;
    width: 100%;
    padding: 4px 0;
    text-align: center;
  }
  td.thumbnail {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    overflow: hidden;
    margin: 0;
    padding: 20px 28px;
    max-height: 70px;
    .icon {
      color: #555;
    }
  }
  td.name {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    border-bottom: none;
    padding: 8px;
    h3 {
      margin: 0 0 0.4em 0;
    }
    h4 {
      margin: 0 0 0.4em 0;
      font-weight: 300;
    }
    .wrapper {
      display: flex;

      .text {
        font-weight: 300;
      }
      .icon {
        color: #555;
      }
    }
  }
  td.latin {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 2;
    grid-row-end: span 1;
  }
  td.period {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 2;
    grid-row-end: span 1;
    justify-self: center;
    align-self: center;
    .wrapper {
      display: flex;
      justify-content: center;
      .icon {
        color: #555;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  td.period,
  td.latin {
    display: none !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    display: grid;
    grid-template-columns: 80px calc(70% - 80px) 30%;
    grid-template-rows: 100%;
    border: none;
    border-top: 1px solid #eee;
  }
  .birds-list td {
    padding: 4px;
    border: none;
  }
  .birds-list td.period {
    width: 100%;
    padding: 4px 0;
    text-align: center;
  }
  td.thumbnail {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    overflow: hidden;
    margin: 0;
    padding: 20px 28px;
    max-height: 70px;
    .icon {
      color: #555;
    }
  }
  td.name {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    border-bottom: none;
    padding: 8px;
    h3 {
      margin: 0 0 0.4em 0;
    }
    h4 {
      margin: 0 0 0.4em 0;
      font-weight: 300;
      font-style:italic;
    }
    .wrapper {
      display: flex;

      .text {
        font-weight: 300;
      }
      .icon {
        color: #555;
      }
    }
  }
  td.latin {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 2;
    grid-row-end: span 1;
  }
  td.period {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 2;
    grid-row-end: span 1;
    justify-self: center;
    align-self: center;
    .wrapper {
      display: flex;
      justify-content: center;
      .icon {
        color: #555;
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.modal-wrapper {
  width: 100%;
  margin: auto;
  height: 400px;
  position: fixed;
  z-index: 111;
  top: 100px;
  left: 0;
  text-align: center;
  .modal-inner {
    display: inline-block;
    h3 {
      font-size: 1.4rem;
      background: #333;
      color: #fff;
      margin: 0;
      padding: 4px 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .modal-wrapper {
    width: 100%;
    margin: auto;
    height: 100vh;
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    text-align: center;
    .modal-inner {
      display: inline-block;
      width:100%;
      height: 100%;
      img {
        max-height: 100vh;
        max-width: 100vw;
      }
      h3 {
        font-size: 1.4rem;
        background: #333;
        color: #fff;
        margin: 0;
        padding: 4px 10px;
      }
    }
  }
  
}