$headline-color: #fff;

.sticky {
	position: -webkit-sticky; /* Safari */
	position: sticky;
}

.location__header{
	z-index: 99;
	top: -10px;
	left: 0;
	display: block;
	background-color: #fff;
	padding-top: 4px;
	border-bottom: 4px solid rgba(255, 255, 255, .3);
}
.location__inner {
	padding: 10px;
}

#sidebar {
    position: absolute;
	top: 0;
	left: 0;
	z-index: 111;
	height: 100vh;
    background: #fff;
	overflow-y: scroll;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	h2 {
		padding: 10px;
		margin: 0;
		background-color: #43a047;
		color: $headline-color;
		font-weight: 400;
		font-size: 24px;
		box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
	}
	h3 {
		font-size: 22px;
		margin-bottom: 15px;
		padding: 5px 10px;
	}
	.location__info {
		font-size: 1.2em;
	}
	.location__detail-title {
		font-size: 1.2em;
	}
	.sidebar__close{
		position: absolute;
		top: 7px;
		right: 12px;
		button {
			color: #fff;
		}
	}
	ul {
		padding: 0;
		margin-bottom: 6em;
		li {
			margin-bottom: 2.4em;
			p {
				margin-top: .4em;
				font-size: 1.2em;
				padding: 0 0px;
			}
			img {
				width: 100%;
				display: block;
			}
			h3 {
				margin:0;
				padding: 0;

			}
		}
	}
}

.fadeInOut {
  overflow: hidden;
  transition: transform 0.3s ease-out;
  height: auto;
  transform: scaleY(1);
  transform-origin: top;

  &.hidden {
    transform: scaleY(0);
  }
}


.hidden {
	display: none;
}

.slider {
    transform: translateX(0%);
	-webkit-transform: translateX(0%);
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
    
@keyframes slide-in {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    0% { transform: translateX(-100%); }
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}



#sidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	border-radius: 6px;
	background-color: #fff;
}

#sidebar::-webkit-scrollbar
{
	width: 12px;
	background-color: #fff;
}

#sidebar::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
	box-shadow: inset 0 0 2px rgba(0,0,0,.3);
	background-color: #aaa;
}

@media (min-height: 601px) {
 	#sidebar {
		width: 400px;
		height: 100vh;
	}
}

@media (max-height: 600px) {
	#sidebar {
		width: 100%; // calc( 100% - 20px);
		max-width: 400px;
		height: 100vh;
	}
}

.go-to-top {
	float: right;
	margin: 0px 5px 20px 5px;
	background: #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
}

.go-to-top:hover {
	color: #eee;
}

.nav__item {
	background-color: #333;
	margin: 10px 0;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
}
.nav__item a {
	display: block;
	text-decoration: none;
	color: #fff;
	font-weight: bold;
}
