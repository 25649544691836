.map__wrapper {
    height: calc( 100vh - 64px );
    width: 100%;
    top: 0;
    margin-top: 64px;
    position: absolute;
    z-index: 100;
}

/*
@media screen and (max-width: 767px) {
    margin-top: 50px;
}
@media screen and (min-width: 767px) {
    margin-top: 64px;
}

*/

#main__header header {
    min-height: 64px !important; 
}

@media (min-width: 0px) and (orientation: landscape){
    .MuiToolbar-regular {
       min-height: 64px !important;
    }
}

.MuiToolbar-regular {
    min-height: 64px !important;
}
