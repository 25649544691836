$main_color: #43a047;

.find-my-location {
    position: fixed;
    left: 0;
    top: 68px;
    padding: 5px 10px;
    background: $main_color;
    z-index: 101;
    button {
      background: $main_color;
      color: #fff;
      border: none;
      line-height: 26px;
      display: inline-flex;
      font-weight: 500;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      align-items: flex-end;
      padding: 5px 10px;
        span {
        margin-left: .2em;
        font-size: 1em;
      }
    }
    button:hover {
      background: #fff;
      color: $main_color;
    }
  }
  