.overlay {
	position: fixed;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100vh;
    z-index: 111;
    background-color: rgba(0,0,0,0.5);
    &.hidden{
          display: none;
      }
}
